//import Hooks
// import { useFormatData } from '../../../Hooks';

//import SASS && Mui material
import { Box } from '@mui/material';
import tableStyle from '../../../components/Layouts/tableStyle.module.scss';
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import { formatteDocument } from './../../../helpers';
import moment from 'moment';
import { formatNumberWithoutDecimals } from '../../../helpers/numberFormatting';
import { useState, useEffect } from 'react';

export const ServiceOrderCart = ({ data = {}, provider }) => {
  const [orderData, setOrderData] = useState(data)

  const tableHeader = [
    {
      title: 'Fecha',
      className: `text-start px-2 ${tableStyle.minWidth100px}`,
    },
    {
      title: 'Hora',
      className: 'col-1 text-start px-2',
    },
    {
      title: 'Servicio',
      className: ' text-start px-2',
    },
    {
      title: 'Profesional',
      className: 'text-start px-2',
    },
    {
      title: 'Sede',
      className: 'col-2 text-start px-2',
    },
    {
      title: 'Valor Servicio',
      className: 'text-end px-2',
    },
    {
      title: 'Descuento',
      className: 'text-end px-2',
    },
    {
      title: 'Copago o Cuota',
      className: 'text-end px-2',
    },
    {
      title: 'Valor empresa',
      className: 'text-end px-2',
    },
  ];

  const tableBodyStructure = el => [
    {
      text: <>{el?.date || '-'}</>,
      className: 'text-start px-2',
    },
    {
      text: <>{el?.hour || '-'}</>,
      className: 'text-start px-2',
    },
    {
      text: <>{el?.name || '-'}</>,
      className: 'text-start px-2',
    },
    {
      text: <> {el?.doctor || '-'} </>,
      className: 'text-start px-2',
    },
    {
      text: <> {el?.siteName || '-'} </>,
      className: 'text-start px-2',
    },
    {
      text: <span>{formatNumberWithoutDecimals(el?.price)}</span>,
      className: 'text-end px-2',
    },
    {
      text: <span>{formatNumberWithoutDecimals(el?.discount)}</span>,
      className: 'text-end px-2',
    },
    {
      text: <span>{el?.copay !== undefined ? formatNumberWithoutDecimals(el?.copay) : '-'}</span>,
      className: 'text-end px-2',
    },
    {
      text: (
        <span>
          {formatNumberWithoutDecimals(el?.corpPrice)}
        </span>
      ),
      className: 'text-end px-2',
    },
  ];
  const listTableBody = provider
    ? orderData?.orderServices?.map(el => ({
      date: moment(orderData?.creationDate)?.format('YYYY-MM-DD'),
      hour: moment(orderData?.creationDate)?.format('h:mm a'),
      name: el?.name || '-',
      doctor: orderData?.providerName || '-',
      siteName: orderData?.siteName || '-',
      price: el?.serviceAmount,
      copay: el?.userAmount,
      corpPrice: el?.cClientAmount,
    })) || []
    : Object.keys(orderData?.service || {})?.length
      ? [{
        ...orderData?.service,
        price: orderData?.amount,
        hour: orderData?.hour,
        date: orderData?.date,
        copay: orderData?.service?.userPriceWithDiscount,
        discount: orderData?.service?.discount
      }]
      : [];

  const tableData = listTableBody.length ? listTableBody?.map(service => tableBodyStructure(service)) : []
  useEffect(() => {
    setOrderData(data)
  }, [data])

  return (
    <Box mb={3}>
      <Box display={'grid'} gridTemplateColumns={'65% 35%'} mb={3}>
        <Box>
          <Box className={`${tableStyle.ordDarkBlueText} fw-bold`}>
            {orderData?.account?.bussinesName || '-'}
          </Box>

          <Box className={`${tableStyle.ordDarkBlueText} ${tableStyle.fs13px}`}>
            <span className={`${tableStyle.ordDarkGrayText}`}>
              <span className={`fw-bold`}>Nit:&nbsp;</span>
              <span>{orderData?.account?.nit || '-'}</span>
              <span className={`fw-bold`}>&nbsp;|&nbsp;</span>
            </span>

            <span className={`${tableStyle.ordDarkGrayText}`}>
              <span className={`fw-bold`}>Teléfono:&nbsp;</span>
              <span>{orderData?.account?.phone || '-'}</span>
              <span className={`fw-bold`}>&nbsp;|&nbsp;</span>
            </span>

            <span className={`${tableStyle.ordDarkGrayText}`}>
              <span className={`fw-bold`}>Email:&nbsp;</span>
              <span>{orderData?.account?.email || '-'}</span>
              <span className={`fw-bold`}>&nbsp;|&nbsp;</span>
            </span>

            <span className={`${tableStyle.ordDarkGrayText}`}>
              <span className={`fw-bold`}>Dirección:&nbsp;</span>
              <span>{orderData?.account?.address || '-'}</span>
            </span>
          </Box>
        </Box>

        <Box textAlign={'end'}>
          <Box className={`${tableStyle.ordAquaMarineText} fw-bold text`}>
            {provider ? 'Orden de Proveedor' : 'Orden de Servicio'} - {orderData?.id}
          </Box>
          <Box className={`${tableStyle.fs13px}`}>&nbsp;</Box>
          <Box className={`${tableStyle.ordDarkGrayText} ${tableStyle.fs13px}`}>
            <span className='fw-bold'>Expedición:&nbsp;</span>
            <span>
              {provider
                ? moment(orderData?.creationDate)?.format('YYYY-MM-DD')
                : orderData?.createdDate || '-'}
            </span>
          </Box>
        </Box>
      </Box>

      <Box
        className={`${tableStyle.ordDarkGrayText}`}
        display={'grid'}
        gridTemplateColumns={'1fr 1fr'}
        gap={2}
        mb={3}
      >
        <Box>
          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Paciente:</span>
            <span>{orderData?.patient?.name || '-'}</span>
          </Box>

          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Empresa:</span>
            <span>{orderData?.company?.name || '-'}</span>
          </Box>

          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Autorización:</span>
            <span>
              {provider ? orderData?.authorization : orderData?.service?.authorizationNumber || '-'}
            </span>
          </Box>
        </Box>

        <Box>
          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Identificación:</span>
            <span>{`${orderData?.patient?.docType} ${provider ? formatteDocument(orderData?.patient?.doc).format : formatteDocument(orderData?.patient?.document).format}`}</span>
          </Box>

          <Box>
            <span className={`fw-bold d-inline-block ${tableStyle.w170px}`}>Contrato:</span>
            <span>{provider ? orderData?.contractName : orderData?.contractName || '-'}</span>
          </Box>
        </Box>
      </Box>

      <Box>
        <Box className={`${tableStyle.ordDarkBlueText} fw-bold`}>Servicios prestados</Box>

        <OrdTable
          hoverYellow
          lastBorderNone
          body={tableData}
          headers={tableHeader}
          theadClassName={`${tableStyle.bgTransparent}`}
        />
      </Box>
    </Box>
  );
};
