import { Box } from '@mui/material';
import { Text } from '../../../components/UI/atoms';
import NumberFormat from 'react-number-format';

import { useTheme } from 'styled-components';

import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

import {
  formatNumberWithoutDecimals,
  getLocaleSeparators
} from '../../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators();

const RelatedService = ({index, data, appIndex, appsData, setAppsData}) => {
  const { colors } = useTheme();

  return (
    <Box key={data.id} className={`${tableStyles.cardStyles} mb-4`}>
      <Box display={'grid'} columnGap='10px' gridTemplateColumns='1fr 100px'>
        <Box display={'grid'} gap='10px' gridTemplateColumns='1fr'>
          <Box
            display={'grid'}
            gap='20px'
            gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'
            alignItems={'start'}
          >
            <div>
              <Text title={'Servicio'} color={colors.gray} fontSize='10px' />
              <Text
                title={`${appIndex + 1}.${index + 1} ${data.name}`}
                color={colors.darkBlue2}
                fontSize="14px"
                fontWeight='bold'
              />
            </div>

            <div>
              <Text title={'Aseguradora'} color={colors.gray} fontSize='10px' />
              <Text
                title={appsData?.[appIndex]?.service?.corporateClient}
                color={colors.darkBlue2}
                fontSize="14px"
              />
            </div>

            <div>
              <Text title={'Contrato'} color={colors.gray} fontSize='10px' />
              <Text
                title={appsData?.[appIndex]?.service?.contract}
                color={colors.darkBlue2}
                fontSize="14px"
              />
            </div>

            <div>
              <Text title={'Grupo de ingreso'} color={colors.gray} fontSize='10px' />
              <Text
                title={appsData?.[appIndex]?.service?.incomeGroup === null ? '-' : appsData?.[appIndex]?.service?.incomeGroup}
                color={colors.darkBlue2}
                fontSize="14px"
              />
            </div>

            <div style={{ borderBottom: '1px solid #A2A3AB' }}>
              <Text title={'Valor Paciente'} color={colors.gray} fontSize='10px' />
              <NumberFormat
                disabled={appsData?.[appIndex]?.copaymentWaived}
                allowNegative={false}
                className={`register-inputs ${tableStyles.darkerBlueText} p-0`}
                placeholder='Ingresa el valor...'
                thousandSeparator={localeSeparators.groupSeparator}
                decimalSeparator={localeSeparators.decimalSeparator}
                isNumericString={true}
                value={appsData?.at(appIndex)?.relatedServices?.[index]?.patientAmount}
                format={valueStr => {
                  if (!valueStr) return '';

                  return formatNumberWithoutDecimals(valueStr);
                }}
                allowLeadingZeros={false}
                style={{
                  maxHeight: '32px',
                  height: '32px',
                  border: 'none',
                  fontSize: '16px',
                  backgroundColor: 'transparent',
                  cursor: 'text',
                }}
                isAllowed={(event) => {
                  const { value } = event;
                  return Number(value) <= Number(appsData?.at(appIndex)?.relatedServices?.[index]?.value)
                }}
                onValueChange={({ value }) => {
                  const newAppsData = appsData.map((app, idx) => {
                    if (idx === index) {
                      const patientAmount = value
                      const companyAmount = app.relatedServices[index].value - patientAmount
                      return {
                        ...app,
                        relatedServices: app.relatedServices.map((item, idx) =>
                          idx === index ? { ...item, patientAmount, companyAmount } : item,
                        ),
                      };
                    }
                    return app
                  })
                  setAppsData(newAppsData)
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RelatedService
